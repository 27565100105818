<!-- imported as is from Alert.vue from mississauga-events-calendar -->
<template>
    <div :class="['alert', `alert-${type}`]">
        <span :class="`icon fal ${icon}`"></span>
        <div class="title h6" v-if="$slots.title">
            <slot name="title" />
        </div>
        <div class="body xsmall">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: "AlertBox",
    props: {
        type: {
            type: String,
            default: 'information',
        },
    },
    computed: {
        icon() {
            switch (this.type) {
                case 'success': {
                    return 'fa-check-circle';
                }

                case 'danger':
                case 'warning': {
                    return 'fa-exclamation-triangle';
                }

                case 'information': {
                    return 'fa-info-circle';
                }

                default: {
                    return 'fa-exclamation-circle';
                }
            }
        },
    },
};
</script>
