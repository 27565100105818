<template>
  <div id="app" class="container" :class="[this.$root.mode == 'presentation' ? 'w-1920 h-1080' : '']">
    <health-check @callback="handleHealthCheck"></health-check>

    <alert-box v-if="this.store.state.didDataChange == 0" type="information" class="mb-3">
      The approve button is disabled because there is no new data available.
    </alert-box>

    <div v-else-if="this.store.state.status == 'error' || this.store.state.didDataChange == -1">
      <alert-box type="danger" class="mb-3">
        We seem to be experiencing some technical difficulties. You can try reloading the page or try after sometime.<br/>
        For any urgent matter, please contact 3-1-1 or 905-615-4311 outside city limits. 
      </alert-box>
      <button class="btn btn-warning" @click="refreshBrowser">Refresh browser</button>
    </div>

    <alert-box v-else-if="this.store.state.didDataChange == 2" type="information" class="mb-3">
        Results not available 
    </alert-box>

    <div v-if="this.store.state.status == 'ready' && this.store.state.dateModified">
      <result-page v-if="this.$root.mode == 'interactive'" :store="this.store.getStoreData()" @handle-filter="handleFilter" />
      <presentation-mode v-if="this.$root.mode == 'presentation'" :store="this.store.getStoreData()" />
    </div>

    <semipolar-spinner
      v-if="this.store.state.status == 'loading'" 
      :animation-duration="2000"
      :size="65"
      color="#2c3e50"
      class="mx-auto my-3"
    />

    <div id="didDataUpdate" :data-value="this.store.state.didDataChange"
         :data-pollTime="this.store.state.pollTime" style="display:none;">
      {{this.store.state.didDataChange}}</div>

    <div id="rawTimestamp" class="d-none" :data-value="this.store.state.dateModified">{{this.store.state.dateModified}}</div>
  </div>
</template>

<script>

import ResultPage from './views/ResultPage.vue';
import PresentationMode from './views/PresentationMode.vue';
import axios from 'axios';
import AlertBox from './components/AlertBox.vue';
import { SemipolarSpinner  } from 'epic-spinners';
import HealthCheck from './components/HealthCheck.vue';
//import { load } from 'recaptcha-v3'


export default {
  name: 'App',
  components: {
    ResultPage,
    PresentationMode,
    AlertBox,
    SemipolarSpinner,
    HealthCheck
  },
  data() {
    return {
      store: {
        state: {
            dateModified: "",
            filter: '',
            candidatesData: {},
            filteredData: [],
            dropdownLabel: '',
            dataRefreshInterval: process.env.VUE_APP_DATA_REFRESH_INTERVAL, //1min
            didDataChange: null, // 1 = yes, 0 = no, -1 something wrong happened, 2 data loaded but mayor/councillors/etc are blank
            pollTime: '', // time when data was polled,
            status: '',
            recaptcha: null,
            reportingPercentage: 0
        },
        setData(data) {
            this.state.dateModified = data.ReportingTime;
            delete data.ReportingTime;
            //this.state.candidatesData = data;
            this.state.candidatesData = Object.assign({}, this.state.candidatesData, data);
            this.state.reportingPercentage = data.ReportingPercentage;
            //this.state.filteredData = data;
            //this.state.filteredData = Object.assign({}, this.state.filteredData, data);
        },
        getStoreData() {
          return this;
        },
        setFilterState(input) {
          this.state.filter = input;
        },
        setFilteredDataState(obj) {
          this.state.filteredData = obj;
        },
        addToFilteredDataState(item) {
          if( Array.isArray(item) ) {
            this.state.filteredData.push(...item);  
          }
          else {
            this.state.filteredData.push(item);
          }
        },
        clearFilteredDataState() {
          this.state.filteredData = [];
        },
        setDropdownLabelState(input) {
          this.state.dropdownLabel = input;
        },
        setDidDataChange(input) {
          this.state.didDataChange = input;
        },
        setPollTime(input) {
          this.state.pollTime = input;
        },
        setStatusState(input) {
          this.state.status = input;
        }
      },
      dataRefresh: null
    }
  },
  methods: {
    handleFilter(filterObj) {
      this.store.setFilterState(filterObj.position);
      switch(filterObj.position) {
          case "":
              this.store.clearFilteredDataState();
              this.store.setFilteredDataState([]);
              this.store.setDropdownLabelState('');
              break;
          case "All":
              this.store.clearFilteredDataState();
              this.store.setFilteredDataState([]);
              this.store.setDropdownLabelState('All categories');
              break;
          case "Mayor":
              this.store.clearFilteredDataState();
              this.store.addToFilteredDataState(this.store.state.candidatesData.Mayor);
              this.store.setDropdownLabelState("Mayor");
              break;
          case "Councillors":
              this.store.clearFilteredDataState();
              this.store.setFilteredDataState(this.store.state.candidatesData.Councillors);
              this.store.setDropdownLabelState("Councillors");
              break;
          case "Ward":
              this.store.clearFilteredDataState();
              this.store.addToFilteredDataState(this.store.state.candidatesData.Councillors[filterObj.ward-1]);
              this.store.state.candidatesData.TrusteeEnglish.forEach(function(element) {
                if( element.WardsAssigned.includes(filterObj.ward) ) {
                  this.store.addToFilteredDataState(element);
                }
              }, this);
              this.store.addToFilteredDataState(this.store.state.candidatesData.TrusteeFrench);
              this.store.setDropdownLabelState(`Ward ${filterObj.ward}`);
              break;
          case "TrusteeEnglish":
              this.store.clearFilteredDataState();
              this.store.setFilteredDataState(this.store.state.candidatesData.TrusteeEnglish);
              this.store.setDropdownLabelState('English School Board Trustee');
              break;
          case "TrusteeFrench":
              this.store.clearFilteredDataState();
              this.store.setFilteredDataState(this.store.state.candidatesData.TrusteeFrench);
              this.store.setDropdownLabelState('French School Board Trustee');
              break;
          default:
      }
    },
    pollEndpoint() {
      let time = new Date(); 
      //console.log(`[${time.toLocaleTimeString()}] polling...`);
      
      return this.getRecaptchaV3Token()
        // .then((resp) => {
          //console.log('recaptcha token received');
          // let config = {
          //   'headers': {'recaptcha-token': resp }
          // };
        //   return true;
        // })
        .then(() => {
          //return axios.get(this.$root.endpoint, config)
          return axios.get(this.$root.endpoint)
          .then( (response) => {
            //console.log(response);
            this.store.setPollTime(time.toLocaleTimeString());
            if( response.data.error === 1 ) {
              //aka if `code` key is in data (because if it's 200 it returns no `code` key but rather all the candidates data)
              this.store.setDidDataChange(-1);
            }
            else if(response.data.length === 0) {
              this.store.setDidDataChange(0);
              //console.log('data did not change');
            } 
            else {
              if(response.data.ReportingTime != null) {
                //console.log('in');
                this.store.setData(response.data);
                this.store.setDidDataChange(1);
              }
              else {
                //console.log('2');
                this.store.setDidDataChange(2);
              }
            }

            this.store.setStatusState('ready');

            return true;
          })
          .catch(() => {
            //console.log(err);
            this.store.setDidDataChange(-1);
            this.store.setStatusState('error');
            return false;
          });
        })
        

        
        // .catch( (error) => {
        //   console.log(error);
        //   this.store.setStatusState('error');
        // }); 
        
      
    },
    handleHealthCheck(response) {
      this.store.setStatusState(response);
      if(this.store.state.status == 'ready') {
        this.pollEndpoint();
        this.dataRefresh = setInterval( this.pollEndpoint, this.store.state.dataRefreshInterval );
      }
    },
    async getRecaptchaV3Token() {
      // this.store.setStatusState('loading');
      // const recaptcha = await load(process.env.VUE_APP_RECAPTCHA_SITE_KEY);
      // const token = await recaptcha.execute('election_results_call_api');
      // return token;
      return true;
    },
    refreshBrowser() {
      window.location.reload(true);
    }  
  },
  mounted() {
    
  },
  unmounted() {
    clearInterval(this.dataRefresh);
  }

};
</script>

<style lang="scss" scoped>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.w-1920 {
  max-width: 1920px;
}

.h-1080 {
  height:1080px;
}

.btn-warning {
  border: 2px solid #AAA;
}
</style>
