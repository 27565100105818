var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "styled-row pb-3" }, [
        _c("span", { staticClass: "last-updated" }, [
          _c("span", { staticClass: "label red" }, [_vm._v("Page updated: ")]),
          _c("span", { staticClass: "date-and-time" }, [
            _c("span", { staticClass: "date" }, [
              _vm._v(_vm._s(_vm.lastUpdatedDate)),
            ]),
            _vm._v(" at "),
            _c("span", { staticClass: "time" }, [
              _vm._v(_vm._s(_vm.lastUpdatedTime)),
            ]),
            _vm._v(" - "),
          ]),
        ]),
        _c("span", { staticClass: "grey-out" }, [
          _vm._v(
            _vm._s(_vm.reportingPercentage) + "% of all City polls reporting"
          ),
        ]),
        _vm._m(0),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "reporting-tooltip grey-out",
        attrs: {
          type: "button",
          "data-toggle": "tooltip",
          "data-placement": "right",
          "data-trigger": "click",
          title:
            "Displays the total percentage of the polling locations in Mississauga that have completed counting the ballots casted.",
        },
      },
      [_c("i", { staticClass: "far fa-info-circle" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }