var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col-md-6 nopadding mb-3" }, [
    _c("div", { staticClass: "candidatesDropdownLabel small" }, [
      _vm._v("Candidates by office"),
    ]),
    _c("div", { staticClass: "candidate-dropdown dropdown mb-4 mb-md-0" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-secondary btn-block",
          attrs: {
            type: "button",
            id: "candidatesdropdownMenuButton",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false",
          },
        },
        [
          _c(
            "span",
            {
              class: [
                !this.store.state.dropdownLabel ? "default-dropdown" : "",
              ],
            },
            [
              _vm._v(
                _vm._s(this.store.state.dropdownLabel || "Select a category")
              ),
            ]
          ),
          _c("i", { staticClass: "far fa-angle-down" }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dropdown-menu w-100",
          attrs: { "aria-labelledby": "candidatesdropdownMenuButton" },
        },
        [
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { "data-position": "All", "data-ward": "", href: "#" },
              on: { click: _vm.emitFilter },
            },
            [_vm._v("All categories")]
          ),
          _c("div", { staticClass: "dropdown-divider" }),
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: { "data-position": "Mayor", "data-ward": "", href: "#" },
              on: { click: _vm.emitFilter },
            },
            [_vm._v("Mayor")]
          ),
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: {
                "data-position": "Councillors",
                "data-ward": "",
                href: "#",
              },
              on: { click: _vm.emitFilter },
            },
            [_vm._v("Councillors")]
          ),
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: {
                "data-position": "TrusteeEnglish",
                "data-ward": "",
                href: "#",
              },
              on: { click: _vm.emitFilter },
            },
            [_vm._v("English School Board Trustee")]
          ),
          _c(
            "a",
            {
              staticClass: "dropdown-item",
              attrs: {
                "data-position": "TrusteeFrench",
                "data-ward": "",
                href: "#",
              },
              on: { click: _vm.emitFilter },
            },
            [_vm._v("French School Board Trustee")]
          ),
          _c("div", { staticClass: "dropdown-divider" }),
          _vm._l(11, function (n) {
            return _c(
              "a",
              {
                key: n,
                staticClass: "dropdown-item",
                attrs: { "data-position": "Ward", "data-ward": n, href: "#" },
                on: { click: _vm.emitFilter },
              },
              [_vm._v("Ward " + _vm._s(n))]
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }