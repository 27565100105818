var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-sm-12 nopadding" },
      [
        _c("update-information", {
          staticClass: "mb-3",
          attrs: {
            lastUpdated: _vm.getLastUpdatedTimestamp,
            lastUpdatedTime: _vm.getLastUpdatedTime,
            lastUpdatedDate: _vm.getLastUpdatedDate,
            reportingPercentage: _vm.getReportingPercentage,
          },
        }),
        _vm.not2024
          ? _c("office-filter", {
              attrs: { store: _vm.store },
              on: { "filter-by": _vm.handleFilter },
            })
          : _vm._e(),
        _c("results-table", { attrs: { store: _vm.store } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }