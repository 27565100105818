var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "presentation-slide-title container w-1920 h-1080 nopadding",
    },
    [
      _c("div", { staticClass: "slide-title-slide h-1080" }, [
        _c("div", { staticClass: "slide-title-label" }, [
          _vm._v("Candidates for"),
        ]),
        _c("div", { staticClass: "slide-title-position" }, [
          _vm._v(_vm._s(this.position)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }