import Vue from 'vue'
import App from './App.vue'
// import json from './data/data.json';

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  data () {
    return {
      //data: {},
      mode: document.querySelector("#app").dataset.mode,
      endpoint: document.querySelector("#app").dataset.endpoint,
      isOfficial: document.querySelector("#app").dataset.isofficial ? document.querySelector("#app").dataset.isofficial : false,
      frenchLabels: document.querySelector("#app").dataset.frenchlabels ? document.querySelector("#app").dataset.frenchlabels : false,
      year: document.querySelector("#app").dataset.year,
    }
  },
}).$mount("#app")
