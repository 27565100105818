var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-sm-12 nopadding" },
      [
        this.store.state.candidatesData.Councillors
          ? _c(
              "vue-glide",
              { attrs: { perView: 1, autoplay: this.getAutoplayInterval } },
              [
                _c(
                  "vue-glide-slide",
                  [
                    _c("presentation-slide-title", {
                      attrs: { position: "Mayor" },
                    }),
                  ],
                  1
                ),
                _vm._l(
                  Math.ceil(
                    _vm.store.state.candidatesData.Mayor.Candidates.length / 10
                  ),
                  function (pageIndex) {
                    return [
                      _c(
                        "vue-glide-slide",
                        { key: "Mayor_info_" + pageIndex },
                        [
                          _c("presentation-slide-information", {
                            attrs: {
                              position: "Mayor",
                              candidates: _vm.store.state.candidatesData.Mayor,
                              page: pageIndex,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  }
                ),
                _vm._l(
                  this.store.state.candidatesData.Councillors,
                  function (item, index) {
                    return [
                      item.Candidates.length != 0
                        ? _c(
                            "vue-glide-slide",
                            { key: "Councillors_title_" + index },
                            [
                              _c("presentation-slide-title", {
                                attrs: {
                                  position:
                                    "Ward " + (index + 1) + " - Councillor",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(
                        Math.ceil(item.Candidates.length / 10),
                        function (pageIndex) {
                          return [
                            _c(
                              "vue-glide-slide",
                              {
                                key:
                                  "Councillors_info_" + index + "_" + pageIndex,
                              },
                              [
                                _c("presentation-slide-information", {
                                  attrs: {
                                    ward: "Ward " + (index + 1),
                                    position: "Councillor",
                                    candidates: item,
                                    page: pageIndex,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        }
                      ),
                      _vm._l(
                        _vm.store.state.candidatesData.TrusteeEnglish,
                        function (TE_item, TE_index) {
                          return [
                            TE_item.WardsAssigned.includes(
                              (index + 1).toString()
                            )
                              ? [
                                  TE_item.Candidates.length != 0
                                    ? _c(
                                        "vue-glide-slide",
                                        {
                                          key:
                                            "TrusteeEnglish_title_" +
                                            index +
                                            "_" +
                                            TE_index,
                                        },
                                        [
                                          _c("presentation-slide-title", {
                                            attrs: { position: TE_item.Title },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._l(
                                    Math.ceil(TE_item.Candidates.length / 10),
                                    function (pageIndex) {
                                      return [
                                        _c(
                                          "vue-glide-slide",
                                          {
                                            key:
                                              "TrusteeEnglish_info_" +
                                              index +
                                              "_" +
                                              TE_index +
                                              "_" +
                                              pageIndex,
                                          },
                                          [
                                            _c(
                                              "presentation-slide-information",
                                              {
                                                attrs: {
                                                  ward: "",
                                                  position: TE_item.Title,
                                                  candidates: TE_item,
                                                  page: pageIndex,
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    }
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        }
                      ),
                      _vm._l(
                        _vm.store.state.candidatesData.TrusteeFrench,
                        function (TF_item, TF_index) {
                          return [
                            TF_item.Candidates.length != 0
                              ? _c(
                                  "vue-glide-slide",
                                  {
                                    key:
                                      "TrusteeFrench_title_" +
                                      index +
                                      "_" +
                                      TF_index,
                                  },
                                  [
                                    _c("presentation-slide-title", {
                                      attrs: { position: TF_item.Title },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._l(
                              Math.ceil(TF_item.Candidates.length / 10),
                              function (pageIndex) {
                                return [
                                  _c(
                                    "vue-glide-slide",
                                    {
                                      key:
                                        "TrusteeFrench_info_" +
                                        index +
                                        "_" +
                                        TF_index +
                                        "_" +
                                        pageIndex,
                                    },
                                    [
                                      _c("presentation-slide-information", {
                                        attrs: {
                                          ward: "",
                                          position: TF_item.Title,
                                          candidates: TF_item,
                                          page: pageIndex,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              }
                            ),
                          ]
                        }
                      ),
                    ]
                  }
                ),
              ],
              2
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }