var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container",
      class: [this.$root.mode == "presentation" ? "w-1920 h-1080" : ""],
      attrs: { id: "app" },
    },
    [
      _c("health-check", { on: { callback: _vm.handleHealthCheck } }),
      this.store.state.didDataChange == 0
        ? _c(
            "alert-box",
            { staticClass: "mb-3", attrs: { type: "information" } },
            [
              _vm._v(
                " The approve button is disabled because there is no new data available. "
              ),
            ]
          )
        : this.store.state.status == "error" ||
          this.store.state.didDataChange == -1
        ? _c(
            "div",
            [
              _c(
                "alert-box",
                { staticClass: "mb-3", attrs: { type: "danger" } },
                [
                  _vm._v(
                    " We seem to be experiencing some technical difficulties. You can try reloading the page or try after sometime."
                  ),
                  _c("br"),
                  _vm._v(
                    " For any urgent matter, please contact 3-1-1 or 905-615-4311 outside city limits. "
                  ),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-warning",
                  on: { click: _vm.refreshBrowser },
                },
                [_vm._v("Refresh browser")]
              ),
            ],
            1
          )
        : this.store.state.didDataChange == 2
        ? _c(
            "alert-box",
            { staticClass: "mb-3", attrs: { type: "information" } },
            [_vm._v(" Results not available ")]
          )
        : _vm._e(),
      this.store.state.status == "ready" && this.store.state.dateModified
        ? _c(
            "div",
            [
              this.$root.mode == "interactive"
                ? _c("result-page", {
                    attrs: { store: this.store.getStoreData() },
                    on: { "handle-filter": _vm.handleFilter },
                  })
                : _vm._e(),
              this.$root.mode == "presentation"
                ? _c("presentation-mode", {
                    attrs: { store: this.store.getStoreData() },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      this.store.state.status == "loading"
        ? _c("semipolar-spinner", {
            staticClass: "mx-auto my-3",
            attrs: { "animation-duration": 2000, size: 65, color: "#2c3e50" },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticStyle: { display: "none" },
          attrs: {
            id: "didDataUpdate",
            "data-value": this.store.state.didDataChange,
            "data-pollTime": this.store.state.pollTime,
          },
        },
        [_vm._v(" " + _vm._s(this.store.state.didDataChange))]
      ),
      _c(
        "div",
        {
          staticClass: "d-none",
          attrs: {
            id: "rawTimestamp",
            "data-value": this.store.state.dateModified,
          },
        },
        [_vm._v(_vm._s(this.store.state.dateModified))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }