var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container table mb-3" }, [
    this.$root.mode != "presentation" && this.index != "Mayor"
      ? _c("div", { staticClass: "row" }, [
          this.index != "Mayor"
            ? _c("div", { staticClass: "col nopadding" }, [
                _c("span", { staticClass: "category-divider" }, [
                  _vm._v(_vm._s(_vm._f("capitalize")(this.item.Title))),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _c("div", { staticClass: "row header" }, [
      _c("div", { staticClass: "col-9" }, [
        _vm._v(" " + _vm._s(_vm.getTableTitle) + " "),
      ]),
      _c("div", { staticClass: "col-3" }, [_vm._v(" Votes ")]),
    ]),
    this.getPaginatedCandidates.length
      ? _c(
          "div",
          { class: { "table-body-p": this.$root.mode == "presentation" } },
          _vm._l(this.getPaginatedCandidates, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "row candidate",
                class: [
                  item.Votes === _vm.getHighestVoteCount && _vm.$root.isOfficial
                    ? "highest"
                    : "",
                ],
                attrs: { item: item, index: index },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "col-9 candidate-name",
                    class: {
                      "candidate-name-p": _vm.$root.mode == "presentation",
                    },
                  },
                  [_vm._v(" " + _vm._s(item.Name) + " ")]
                ),
                _c("div", { staticClass: "col-3" }, [
                  _vm._v(" " + _vm._s(item.Votes) + " "),
                ]),
              ]
            )
          }),
          0
        )
      : _c("div", [
          _c("div", { staticClass: "row candidate" }, [
            _c(
              "div",
              {
                staticClass: "col-9 candidate-name",
                class: { "candidate-name-p": _vm.$root.mode == "presentation" },
              },
              [
                _c("span", { staticClass: "font-italic" }, [
                  _vm._v("No candidate(s)"),
                ]),
              ]
            ),
            _c("div", { staticClass: "col-3" }),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }