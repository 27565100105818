<template>
    <div class="row">
        <div class="col-sm-12 nopadding">
            <vue-glide v-if="this.store.state.candidatesData.Councillors" :perView="1" :autoplay="this.getAutoplayInterval">
                <!-- Mayor -->
                <vue-glide-slide>
                    <presentation-slide-title position="Mayor" />
                </vue-glide-slide>
                <template v-for="pageIndex in Math.ceil(store.state.candidatesData.Mayor.Candidates.length/10)"> 
                    <vue-glide-slide :key="`Mayor_info_${pageIndex}`">
                        <presentation-slide-information position="Mayor" :candidates="store.state.candidatesData.Mayor" :page="pageIndex" />
                    </vue-glide-slide>
                </template>

                <template v-for="(item, index) in this.store.state.candidatesData.Councillors">
                    <!-- Councillors -->
                    <vue-glide-slide v-if="item.Candidates.length != 0" :key="`Councillors_title_${index}`">
                        <presentation-slide-title :position="`Ward ${index+1} - Councillor`" />
                    </vue-glide-slide>
                    <template v-for="pageIndex in Math.ceil(item.Candidates.length/10)"> 
                        <vue-glide-slide :key="`Councillors_info_${index}_${pageIndex}`">
                            <presentation-slide-information :ward="`Ward ${index+1}`" position="Councillor" :candidates="item" :page="pageIndex" />
                        </vue-glide-slide>
                    </template>

                    <!-- English Trustees -->
                    <template v-for="(TE_item, TE_index) in store.state.candidatesData.TrusteeEnglish">
                        <template v-if="TE_item.WardsAssigned.includes((index+1).toString())">
                            <vue-glide-slide v-if="TE_item.Candidates.length != 0" :key="`TrusteeEnglish_title_${index}_${TE_index}`">
                                <presentation-slide-title :position="TE_item.Title" />
                            </vue-glide-slide>
                            <template v-for="pageIndex in Math.ceil(TE_item.Candidates.length/10)"> 
                                <vue-glide-slide :key="`TrusteeEnglish_info_${index}_${TE_index}_${pageIndex}`">
                                    <presentation-slide-information ward="" :position="TE_item.Title" :candidates="TE_item" :page="pageIndex" />
                                </vue-glide-slide>
                            </template>
                        </template>
                    </template>

                    <!-- French Trustees -->
                    <template v-for="(TF_item, TF_index) in store.state.candidatesData.TrusteeFrench">
                        <vue-glide-slide v-if="TF_item.Candidates.length != 0" :key="`TrusteeFrench_title_${index}_${TF_index}`">
                            <presentation-slide-title :position="TF_item.Title" />
                        </vue-glide-slide>
                        <template v-for="pageIndex in Math.ceil(TF_item.Candidates.length/10)"> 
                            <vue-glide-slide :key="`TrusteeFrench_info_${index}_${TF_index}_${pageIndex}`">
                                <presentation-slide-information ward="" :position="TF_item.Title" :candidates="TF_item" :page="pageIndex" />
                            </vue-glide-slide>
                        </template>
                    </template>
                </template>
            </vue-glide>
        </div>
    </div>
</template>

<script>
import { Glide, GlideSlide } from 'vue-glide-js';
import PresentationSlideInformation from '../components/PresentationSlideInformation.vue';
import PresentationSlideTitle from '../components/PresentationSlideTitle.vue';

export default {
    components: {
        [Glide.name]: Glide,
        [GlideSlide.name]: GlideSlide,
        PresentationSlideInformation,
        PresentationSlideTitle
     },
    props: {
        store: { type: Object }
    },
    computed: {
        getAutoplayInterval() {
            return Number(process.env.VUE_APP_SLIDER_AUTOPLAY_INTERVAL);
        }
    },
    name: 'PresentationMode',
    mounted() { 
        //console.log(this.store);
    }
};
</script>
<style src="@glidejs/glide/dist/css/glide.core.min.css"></style>
<style src="@glidejs/glide/dist/css/glide.theme.min.css"></style>
