<template>
    <div class="container table mb-3">
        <div v-if="this.$root.mode != 'presentation' && this.index != 'Mayor'" class="row">
            <div v-if="this.index != 'Mayor'" class="col nopadding">
                <span class="category-divider">{{this.item.Title | capitalize}}</span>
            </div>
        </div>
        <div class="row header">
            <div class="col-9">
               {{getTableTitle}}
            </div>
            <div class="col-3">
                Votes
            </div>
        </div>
        <div v-if="this.getPaginatedCandidates.length" :class="{'table-body-p': this.$root.mode == 'presentation'}">
            <div 
                v-for="item,index in this.getPaginatedCandidates"
                :item="item"
                :index="index"
                :key="index"
                class="row candidate"
                :class="[item.Votes === getHighestVoteCount && $root.isOfficial ? 'highest' : '']"
            >
                <div class="col-9 candidate-name" :class="{'candidate-name-p': $root.mode == 'presentation'}">
                    {{item.Name}}
                </div>
                <div class="col-3">
                    {{item.Votes}}
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row candidate">
            <div class="col-9 candidate-name" :class="{'candidate-name-p': $root.mode == 'presentation'}">
                <span class="font-italic">No candidate(s)</span>
            </div>
            <div class="col-3"></div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ATable',
    props: {
        item: {},
        index: {},
        categoryLabel: {
            type: String
        },
        page: {type: Number}
    },
    filters: {
      capitalize: function (data) {
        var capitalized = []
        data.split(' ').forEach(word => {
          capitalized.push(
            word.charAt(0).toUpperCase() +
            word.slice(1).toLowerCase()
          )
        })
        return capitalized.join(' ')
      }
    },
    computed: {
        getTableTitle() {
            //console.log(this);
            switch(this.index) {
                case "Mayor":
                    if(this.$root.frenchLabels) {
                      return "Mayor / Maire (ou mairesse)";
                    } else {
                      return "Mayor";
                    }
                case "TrusteeEnglish":
                    return "English Trustee";
                case "TrusteeFrench":
                    return "French Trustee";
                case "Councillor":
                    return "Councillor";
                default:
                  if(this.$root.frenchLabels) {
                    return "Candidate / Candidat";
                  } else {
                    return "Candidate";
                  }
            }
        },
        getPaginatedCandidates() {
            if(!this.page) return this.item.Candidates;

            return this.item.Candidates.slice( (this.page - 1) * 10, ((this.page - 1) * 10) + 10 );
        },
        getHighestVoteCount() {
            if(!this.getPaginatedCandidates) return '';

            let highest = this.getPaginatedCandidates.reduce(function(prev, current) { 
                return Number(prev.Votes) > Number(current.Votes) ? prev : current;
            }, 0);
            return highest.Votes;
        }
    },
    mounted() {
        
    }
};
</script>
<style lang="scss" scoped>
    .table {
        font-family: "Gotham SSm A";
        font-weight:500;
    }
    .header {
        background-color: #3F78C2;
        color:white;
        text-transform: capitalize;
        line-height:130%;
        font-size:2rem;
    }

    .table-body-p {
        font-size:2.15rem;
        color:black;
    }

    .candidate {
        background-color: #F1F1F1;
    }
    .row {
        border-radius:4px;
        margin-bottom: 4px;
        padding-top:.5rem;
        padding-bottom:.5rem;
    }
    .category-divider {
        text-transform: capitalize;
    }
    .candidate-name {
        font-family: "Gotham SSm A";
    }

    .candidate-name-p {
        font-weight:700;
    }

    .nopadding {
        padding-left:0px;
        padding-right:0px;
    }

    .highest {
        border: 3px solid #0FA3DD;
        background-color: #C1E4F1;

    }
</style>
