<template>
    <div class="presentation-slide-title container w-1920 h-1080 nopadding">
        <div class="slide-title-slide h-1080">
            <div class="slide-title-label">Candidates for</div>
            <div class="slide-title-position">{{this.position}}</div>
        </div>
    </div>
</template>

<script>
//import ATable from '../components/ATable.vue';

export default {
    components: { },
    name: 'PresentationSlideTitle',
    props: {
        position: {
            type: String
        }
    },
};
</script>

<style lang="scss" scoped>
.slide-title-label {
    font-size: 3.7rem;
    font-weight:500;
    font-family: "Gotham SSm A";
}
.slide-title-position {
    font-size: 4.65rem;
    font-weight:700;
    text-align:center;
    padding-left: 3rem;
    padding-right:3rem;
    line-height:120%;
    font-family: "Gotham SSm A";
}
.slide-title-slide {
    background-color: #3177C2;
    color:white;
    display:flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
    min-height:600px;
}

.w-1920 {
  max-width: 1920px;
}

.h-1080 {
  height:1080px;
}
</style>
