<template>
    <div class="row">
        <div class="col">
            <div class="styled-row pb-3">
            <span class="last-updated">
                <span class="label red">Page updated: </span>
                <span class="date-and-time">
                    <span class="date">{{lastUpdatedDate}}</span> at <span class="time">{{lastUpdatedTime}}</span> -
                </span>
            </span> 
             
            <span class="grey-out">{{reportingPercentage}}% of all City polls reporting</span> 
            
            
            <button type="button" class="reporting-tooltip grey-out" data-toggle="tooltip" data-placement="right" data-trigger="click" title="Displays the total percentage of the polling locations in Mississauga that have completed counting the ballots casted.">
                <i class="far fa-info-circle"></i>
            </button>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UpdateInformation',
    methods: {
        emitFilter(e) {
            this.$emit('filter-by', {position: e.currentTarget.getAttribute('data-position'), ward: e.currentTarget.getAttribute('data-ward') } );
        }
    },
    props: {
        lastUpdated: {},
        lastUpdatedDate: {},
        lastUpdatedTime: {},
        reportingPercentage: {}
    },
    mounted() {
        window.jQuery('[data-toggle="tooltip"]').tooltip();
    }
};
</script>

<style scoped>
.last-updated {
    font-family: "Gotham Narrow SSm A";
    font-weight: 500;
}
.red {
    color: #B80600;
}

.grey-out {
    color: #707070;
}

.styled-row {
    border-bottom: 5px solid #e7e7e7;
}

.reporting-tooltip {
    background-color:transparent;
    border:none;
}

.date-and-time {display:block;}
@media screen and (min-width: 768px) {
    .date-and-time {
        display:inline;
    }
}

.date, .time {
    white-space: nowrap;
}

</style>