var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: ["alert", "alert-" + _vm.type] }, [
    _c("span", { class: "icon fal " + _vm.icon }),
    _vm.$slots.title
      ? _c("div", { staticClass: "title h6" }, [_vm._t("title")], 2)
      : _vm._e(),
    _c("div", { staticClass: "body xsmall" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }