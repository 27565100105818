<template>
    <div class="results-table container nopadding">
        <div class="row mb-2">
            <div class="col small candidate-count-label">
                <span class="candidate-count">{{getCandidatesCount || 0}}</span> candidates
            </div>
            <div class="col d-flex align-items-end justify-content-end">
                <div class="smaller sorting-text"><span class="sorting-label">Sorting:</span> Alphabetically</div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div v-if="this.store.state.filter == '' || this.store.state.filter == 'All'">
                    <!-- Mayor -->
                    <a-table 
                        :item="this.store.state.candidatesData.Mayor"
                        index="Mayor"
                        key="Mayor"
                    />

                    <!-- Councillors -->
                    <a-table 
                        v-for="item,index in this.store.state.candidatesData.Councillors"
                        :item="item" 
                        :index="index"
                        :key="`Councillors_${index}`"
                    />

                    <!-- English trustees -->
                    <a-table 
                        v-for="item,index in this.store.state.candidatesData.TrusteeEnglish"
                        :item="item" 
                        index="TrusteeEnglish"
                        :key="`TrusteeEnglish_${index}`"
                    />

                    <!-- French trustees -->
                    <a-table 
                        v-for="item,index in this.store.state.candidatesData.TrusteeFrench"
                        :item="item" 
                        index="TrusteeFrench"
                        :key="`TrusteeFrench_${index}`"
                    />
                </div>
                <div v-else-if="Array.isArray(this.store.state.filteredData)">
                    <a-table 
                        v-for="(item, index) in this.store.state.filteredData"
                        :item="item"
                        :index="getFilterData" 
                        categoryLabel="categoryLabel"
                        tableLabel=""
                        :key="index"
                    />
                </div>
                <div v-else>
                    <a-table
                        :item="this.store.state.filteredData"
                        index="index" 
                        categoryLabel="categoryLabel"
                        tableLabel=""
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ATable from '../components/ATable.vue';

export default {
    components: { ATable },
    name: 'ResultsTable',
    props: {
        store: {
            type: Object
        },
        lastUpdated: {}
    },
    computed: {
        getCandidatesCount() {
            if(this.store.state.filter == '' || this.store.state.filter == 'All') {
                let sum = [];
                sum.push( this.store.state.candidatesData.Mayor.Candidates.length ); //mayor
                sum.push( this.store.state.candidatesData.Councillors.reduce( (previousValue, currentValue) => { //councillors
                    return previousValue + currentValue.Candidates.length;
                }, 0)); 
                sum.push( this.store.state.candidatesData.TrusteeEnglish.reduce( (previousValue, currentValue) => { //TrusteeEnglish
                    return previousValue + currentValue.Candidates.length;
                }, 0));
                sum.push( this.store.state.candidatesData.TrusteeFrench.reduce( (previousValue, currentValue) => { //TrusteeFrench
                    return previousValue + currentValue.Candidates.length;
                }, 0));
                //DCA: redundant code can be improved above..just running out of time

                return sum.reduce((prev, current) => prev + current, 0);
            }
            else if( Array.isArray(this.store.state.filteredData) ) {
                let sum = []
                this.store.state.filteredData.forEach(function(el) {
                    sum.push(el.Candidates.length);
                });
                return sum.reduce((prev, current) => prev + current, 0);
            }
            else {
                return this.store.state.filteredData.Candidates.length;
            }
        },
        getFilterData() {
            return this.store.state.filter;
        }
    }
};
</script>
<style lang="scss">
.smaller {
    font-size:0.7rem!important;
}

.candidate-count {
    font-family: "Gotham SSm A";
    font-weight: 500;
    font-size: .8rem!important;
    color: #000000;
}

.results-table .candidate-count-label {
    color: #000000;
    font-weight: 300;
    font-size: .8rem!important;
}

.results-table .header{
  font-size: .9rem;
}

.category-divider {
    font-weight:700;
    background-color: rgb(247, 247, 247);
    padding-right:10px;
}
.category-divider::after {
    content: " ";
    position:absolute;
    left:20px;
    right:0;
    top:15px;
    z-index:-1;
    //width:100%;
    border-bottom:1px solid #B9B9B9;
}

.sorting-label {
    font-weight:500;
}
</style>
