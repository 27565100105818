<template>
    <div></div>
</template>

<script>
import axios from 'axios';

const HEALTH_CHECK_URL = "https://os-"+process.env.VUE_APP_ENV_VAR+"-api.mississauga.ca/api/v1/electionresult";

export default {
    name: "HealthCheck",
    methods: {
        doHealthCheck() {
           axios.get(HEALTH_CHECK_URL)
            .then(() => {
                this.$emit('callback', 'ready');
                //console.log('Healthcheck: ready');
            })
            .catch(() => {
                this.$emit('callback', 'error');
                //console.log('Healthcheck: error');
                //console.log(e);
            }); 
        },
        setHealthCheck(input) {
            this.$emit('callback', input);
            //console.log('Healthcheck: '+input);
        }
    },
    mounted() {
        //console.log('Healthcheck initialized');
        this.setHealthCheck('loading');
        this.doHealthCheck();
    }
};
</script>
