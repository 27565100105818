<template>
    <div class="col-md-6 nopadding mb-3">
        <div class="candidatesDropdownLabel small">Candidates by office</div>
        <div class="candidate-dropdown dropdown mb-4 mb-md-0">
            <button class="btn btn-secondary btn-block" type="button" id="candidatesdropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span :class="[!this.store.state.dropdownLabel ? 'default-dropdown' : '']">{{this.store.state.dropdownLabel || "Select a category"}}</span>
                <i class="far fa-angle-down"></i>
            </button>
            <div class="dropdown-menu w-100" aria-labelledby="candidatesdropdownMenuButton">
                <a class="dropdown-item" data-position="All" data-ward="" @click="emitFilter" href="#">All categories</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" data-position="Mayor" data-ward="" @click="emitFilter" href="#">Mayor</a>
                <a class="dropdown-item" data-position="Councillors" data-ward="" @click="emitFilter" href="#">Councillors</a>
                <a class="dropdown-item" data-position="TrusteeEnglish" data-ward="" @click="emitFilter" href="#">English School Board Trustee</a>
                <a class="dropdown-item" data-position="TrusteeFrench" data-ward="" @click="emitFilter" href="#">French School Board Trustee</a>
                <div class="dropdown-divider"></div>
                <a v-for="n in 11" :key="n" class="dropdown-item" data-position="Ward" :data-ward="n" @click="emitFilter" href="#">Ward {{n}}</a>                
            </div>
        </div>
    </div>
</template>

<script>
//import EventList from '../list/EventList.vue';

export default {
    //components: { EventList },
    name: 'ResultPage',
    methods: {
        emitFilter(e) {
            this.$emit('filter-by', {position: e.currentTarget.getAttribute('data-position'), ward: e.currentTarget.getAttribute('data-ward') } );
        }
    },
    props: {
        store: {type: Object}
    }
};
</script>

<style lang="scss" scoped>
.dropdown-item {
    text-decoration: none;
    color:#16181b;

    &:hover {
        background-color: #1BBCE8;
    }
}

.candidatesDropdownLabel {
    font-weight:500;
}

#candidatesdropdownMenuButton {
    border: 2px solid #6E6E6E;
    color: #2E2E2E;
    border-radius:4px;
    text-align:left;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display:flex;
    align-items: center;
    justify-content: space-between;
    font-weight:400;

    &:hover {
        background-color:white;
        box-shadow: 0 0 0 2px #5bc2e7;
    }
}

.dropdown-menu {
    max-height: 50vh;
    overflow-y: scroll;
}

.candidate-dropdown {
    width:100%;
}

.default-dropdown {
    color: #5c5c5c;;
}

</style>