<template>
    <div class="row">
        <div class="col-sm-12 nopadding">
            <update-information class="mb-3" :lastUpdated="getLastUpdatedTimestamp" :lastUpdatedTime="getLastUpdatedTime" :lastUpdatedDate="getLastUpdatedDate" :reportingPercentage="getReportingPercentage"></update-information>
            <!-- Check if the year variable meets your condition to show the office-filter -->
            <office-filter v-if="not2024" @filter-by="handleFilter" :store="store" />
            <results-table :store="store" />
            <!-- the v-if attr above is just a patch to check first if the API data's been downloaded already; only then should the component load or else it fires so much warnings
            because data's not in yet and it shows in the console log. It actually immediately gets replaced with the correct, downloaded data so when you preview it
            in the browser, data and everything works but there's a warning...which was confusing and freaked the shit out of me and made me panic, "where tf is this warning coming from!?" -->
            
        </div>
    </div>
</template>

<script>
import OfficeFilter from '../components/OfficeFilter.vue';
import ResultsTable from '../components/ResultsTable.vue';
import UpdateInformation from '../components/UpdateInformation.vue';

export default {
    components: { OfficeFilter, ResultsTable, UpdateInformation },
    name: 'ResultPage',
    props: {
        store: {
            type: Object
        }
    },
    methods: {
        handleFilter(filterObj) {
            //console.log(filterObj);
            this.$emit('handle-filter', filterObj);
        },
    },
    computed: {
        // Computed property to determine whether to show office-filter based on this.$root.year
        not2024() {
          // Replace the condition below with your logic based on this.$root.year
          return this.$root.year !== '2024';
        },
        getLastUpdatedTimestamp() {
            let date = new Date(this.store.state.dateModified);
            return Intl.DateTimeFormat('en-CA', {year:'numeric', month:'short', day:'numeric', hour:'numeric', minute:'numeric', second:'numeric'}).format(date); 
        },
        getLastUpdatedTime() {
            let date = new Date(this.store.state.dateModified);
            return Intl.DateTimeFormat('en-CA', {hour:'numeric', minute:'numeric'}).format(date); 
        },
        getLastUpdatedDate() {
            let date = new Date(this.store.state.dateModified);
            return Intl.DateTimeFormat('en-CA', { month:'short', day:'numeric'}).format(date); 
        },
        getReportingPercentage() {
            return this.store.state.reportingPercentage;
        }
    }
};
</script>
