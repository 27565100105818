var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "results-table container nopadding" }, [
    _c("div", { staticClass: "row mb-2" }, [
      _c("div", { staticClass: "col small candidate-count-label" }, [
        _c("span", { staticClass: "candidate-count" }, [
          _vm._v(_vm._s(_vm.getCandidatesCount || 0)),
        ]),
        _vm._v(" candidates "),
      ]),
      _vm._m(0),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        this.store.state.filter == "" || this.store.state.filter == "All"
          ? _c(
              "div",
              [
                _c("a-table", {
                  key: "Mayor",
                  attrs: {
                    item: this.store.state.candidatesData.Mayor,
                    index: "Mayor",
                  },
                }),
                _vm._l(
                  this.store.state.candidatesData.Councillors,
                  function (item, index) {
                    return _c("a-table", {
                      key: "Councillors_" + index,
                      attrs: { item: item, index: index },
                    })
                  }
                ),
                _vm._l(
                  this.store.state.candidatesData.TrusteeEnglish,
                  function (item, index) {
                    return _c("a-table", {
                      key: "TrusteeEnglish_" + index,
                      attrs: { item: item, index: "TrusteeEnglish" },
                    })
                  }
                ),
                _vm._l(
                  this.store.state.candidatesData.TrusteeFrench,
                  function (item, index) {
                    return _c("a-table", {
                      key: "TrusteeFrench_" + index,
                      attrs: { item: item, index: "TrusteeFrench" },
                    })
                  }
                ),
              ],
              2
            )
          : Array.isArray(this.store.state.filteredData)
          ? _c(
              "div",
              _vm._l(this.store.state.filteredData, function (item, index) {
                return _c("a-table", {
                  key: index,
                  attrs: {
                    item: item,
                    index: _vm.getFilterData,
                    categoryLabel: "categoryLabel",
                    tableLabel: "",
                  },
                })
              }),
              1
            )
          : _c(
              "div",
              [
                _c("a-table", {
                  attrs: {
                    item: this.store.state.filteredData,
                    index: "index",
                    categoryLabel: "categoryLabel",
                    tableLabel: "",
                  },
                }),
              ],
              1
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col d-flex align-items-end justify-content-end" },
      [
        _c("div", { staticClass: "smaller sorting-text" }, [
          _c("span", { staticClass: "sorting-label" }, [_vm._v("Sorting:")]),
          _vm._v(" Alphabetically"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }